.switchSelector {
    border-radius: 90px;
    overflow: hidden;
    border: 2px solid #4292c6;
    display: flex;
    padding: 2px;
    position: relative;
}

.switchSelectorBackground {
    position: absolute;
    top: 0;
    margin: 2px;
    left: 0;
    bottom: 0;
    width: calc(50% - 2px);
    border-radius: 90px;
    background-color: #4292c6;
    transition: left .5s;
}

.switchSelectorBackgroundSecond {
    left: calc(50% - 2px);
}

.switchSelectorItem {
    z-index: 2;
    padding: 10px 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #4292c6;
    transition: color .5s;
    cursor: pointer;
}

.switchSelectorItem--selected {
    color: white;
}